import { Navigate, useParams } from 'react-router';
import { ErrorView } from '../../../components/error/ErrorView';
import { SettingsLayout } from '../../../components/layouts/SettingsLayout';
import { LoadingSkeleton } from '../../../components/loading/LoadingSkeleton';
import { NotificationRow } from '../../../components/settings/notifications/NotificationRow';

import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ArtistUserSettingsFeature } from '../../../graphql/generated/types';
import {
  parseNotificationFeature,
  useArtistUserNotificationSettings,
} from '../../../hooks/useArtistUserNotificationSettings';
import { LoginStatus } from '../../../types/authTypes';

export function ArtistNotificationsSettingsScreen() {
  const { artistHandle } = useParams();
  const { loginStatus } = useAuthContext();
  const { data, isLoading, isError, refetch, artistName } = useArtistUserNotificationSettings({
    artistHandle: artistHandle ?? '',
  });

  if (loginStatus === LoginStatus.LOGGED_OUT) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (isError || artistHandle == null) {
    return (
      <SettingsLayout title={`${artistName ?? 'Artist'} notifications`}>
        <ErrorView onRetryClick={refetch} withVaultTheme={false} />
      </SettingsLayout>
    );
  }

  if (isLoading || loginStatus === LoginStatus.LOADING) {
    return (
      <SettingsLayout title="">
        <LoadingSkeleton className="h-[60px] w-full" />
      </SettingsLayout>
    );
  }

  return (
    <SettingsLayout title={`${artistName} notifications`}>
      <ul
        role="list"
        className="w-full list-none space-y-0.5 p-0"
        aria-label="Notification settings"
      >
        {data.map(item => {
          const content = parseNotificationFeature({
            feature: item.feature,
          });
          let value =
            item.isPushEnabled && item.isSmsEnabled
              ? 'SMS & Push'
              : item.isPushEnabled
                ? 'Push only'
                : item.isSmsEnabled
                  ? 'SMS only'
                  : 'Off';

          if (
            item.feature === ArtistUserSettingsFeature.GroupChatMessage &&
            value === 'Push only' &&
            item.radioFields?.some(({ key, enabled }) => enabled && key === 'artistOnly')
          ) {
            value = 'Artist only';
          }

          return (
            <li key={item.feature} className="overflow-hidden first:rounded-t-md last:rounded-b-md">
              <NotificationRow
                icon={content.icon}
                label={content.label}
                value={value}
                href={`/settings/artist-notification/${artistHandle}/${content.path}`}
              />
            </li>
          );
        })}
      </ul>
    </SettingsLayout>
  );
}
