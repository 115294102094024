import React from 'react';
import prettyBytes from 'pretty-bytes';
import { useTrackUpload } from '../../contexts/TrackUploadContext';
import { Text } from '../common/Text';
import { View } from '../common/View';

export function TrackUploadingTracker() {
  const { isUploadingTrack, retryUpload } = useTrackUpload();

  if (!isUploadingTrack && !retryUpload) return null;

  return <TrackUploadingView />;
}

function TrackUploadingView() {
  const { progressSize, totalSize, retryUpload } = useTrackUpload();

  const percentage = Math.round((progressSize / totalSize) * 100);

  const totalSizePretty = React.useMemo(
    () => prettyBytes(totalSize, { space: false }),
    [totalSize],
  );

  const progressSizePretty = React.useMemo(
    () => prettyBytes(progressSize, { space: false }),
    [progressSize],
  );

  if (retryUpload) {
    return (
      <View className="absolute bottom-[100px] z-uploadTooltip flex w-full flex-row justify-center md2:max-lt:ml-[150px]">
        <View className="mx-4 flex w-full flex-col gap-2 rounded-lg bg-destructive700 px-4 py-3 sm:w-[66%] md:w-[50%] md2:max-w-[520px] lg:w-[30%]">
          <View className="flex flex-1 flex-row items-center justify-between py-3">
            <View className="flex flex-col gap-1 font-base">
              <View className="flex flex-row gap-1">
                <span className="text-base-m font-semibold">Your upload failed.</span>

                <span
                  onClick={retryUpload}
                  className="cursor-pointer text-base-m font-semibold underline"
                >
                  Try again.
                </span>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }

  return (
    <View className="absolute bottom-[100px] z-uploadTooltip flex w-full flex-row justify-center md2:max-lt:ml-[150px]">
      <View className="mx-4 flex w-full flex-col gap-2 rounded-lg bg-base600 px-4 py-3 sm:w-[66%] md:w-[50%] md2:max-w-[520px] lg:w-[30%]">
        <View className="flex flex-1 flex-row items-center justify-between ">
          <View className="flex flex-col gap-1 font-base">
            <Text className="text-base-m font-semibold">Uploading 1 file...</Text>
            <Text className="text-base-s font-normal">
              <span className="text-yellow100">{progressSizePretty}</span>
              <span className="text-base500"> / {totalSizePretty}</span>
            </Text>
          </View>
        </View>
        <View className="h-1 w-full rounded-full bg-white bg-opacity-30">
          <View
            className="h-1 w-1/4 rounded-full bg-yellow100 transition-all"
            style={{
              width: `${percentage}%`,
            }}
          />
        </View>
      </View>
    </View>
  );
}
