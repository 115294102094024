import { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isFuture } from 'date-fns';
import { compact } from 'lodash-es';
import millify from 'millify';
import { useInView } from 'react-intersection-observer';
import { Navigate, useNavigate } from 'react-router';
import { Virtuoso } from 'react-virtuoso';
import { twMerge } from 'tailwind-merge';
import { faClose, faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faEllipsis } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPen } from '@soundxyz/font-awesome/pro-regular-svg-icons';

import { faTrash } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faGlobe } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowUpRightFromSquare } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faEye } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faEyeSlash } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowUpFromBracket } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faReceipt, faSpinner } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { DROP_INFO_ACTIONS, PILLARS } from '@soundxyz/vault-logs-utils/dist/constants';
import { CampaignStatusBanner } from '../../components/banner/CampaignStatusBanner';
import { BackButton } from '../../components/buttons/BackButton';
import { Button } from '../../components/buttons/Button';
import { useCampaignForm } from '../../components/campaign/useCampaignForm';
import { Image } from '../../components/common/Image';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { ErrorView } from '../../components/error/ErrorView';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { EmptyStateView } from '../../components/views/EmptyStateView';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useInfiniteQuery } from '../../graphql/client';
import {
  ArtistMembershipReceiptTypenames,
  CampaignInsightHeaderFragmentDoc,
  CampaignInsightsDocument,
  MemberRowFragmentDoc,
  ReleaseCampaignState,
  ReleaseCampaignStatus,
} from '../../graphql/generated';
import { type FragmentType, getFragment } from '../../graphql/generated';
import { useLogInfo } from '../../hooks/logger/useLogInfo';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useCopy } from '../../hooks/useCopy';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { generateShareLink } from '../../utils/linkUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { formatDateString } from '../../utils/textUtils';
import { MemberRow, MemberRowSkeleton } from './MemberRow';

gql(/* GraphQL */ `
  query CampaignInsights(
    $id: UUID!
    $artistHandle: String!
    $after: String
    $first: Int
    $receiptType: ArtistMembershipReceiptTypenames!
  ) {
    allArtistMembershipReceipts(
      artistHandle: $artistHandle
      releaseCampaignIds: [$id]
      after: $after
      first: $first
      receiptTypes: [$receiptType]
    ) {
      edges {
        cursor
        node {
          id
          createdAt
          user {
            id
            ...userRow
          }
          membership {
            createdAt
            receipts
            vaultSubscription {
              id
              createdAt
              ...MemberRow
            }
          }
          artist {
            id
            mainVault {
              id
              type
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  fragment CampaignInsightHeader on ReleaseCampaignPrivateInfo {
    id
    title
    initialReleaseImageUrl
    coverImage {
      id
      campaignCoverImageUrl: imageOptimizedUrl
    }
    currentState
    linkValue
    status
    description
    releaseDate
    newSubscriptionCount
    receiptCount
    artist {
      id
      profileImage {
        id
        artistFullProfileImageUrl: imageOptimizedUrl
      }
    }
  }
`);

const LIMIT = 50;

export function CampaignInsightsView({
  campaign,
}: {
  campaign: FragmentType<CampaignInsightHeaderFragmentDoc>;
}) {
  const { id, linkValue, status, artist, currentState, releaseDate } = getFragment(
    CampaignInsightHeaderFragmentDoc,
    campaign,
  );
  const { artistHandle } = useArtistHandle();

  const { loggedInUser, loginStatus } = useAuthContext();
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();

  const navigate = useNavigate();

  const [bottomRef, isAtBottom] = useInView({
    threshold: 0.1,
  });

  const { isDesktop } = useWindow();

  const {
    deleteCampaign,
    releaseCampaignDeleting,
    deactivateCampaign,
    releaseCampaignDeactivating,
    reactivateCampaign,
    releaseCampaignReactivating,
  } = useCampaignForm();

  useVaultTheme();
  const logInfo = useLogInfo();

  const [membersReceiptFilter, setMembersReceiptFilter] =
    useState<ArtistMembershipReceiptTypenames>(
      ArtistMembershipReceiptTypenames.ArtistMembershipPresaveReceipt,
    );

  const {
    orderedList: members,
    isError,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isInitialLoading,
    isLoadingError,
  } = useInfiniteQuery(CampaignInsightsDocument, {
    filterQueryKey: {
      artistHandle,
      id,
      receiptType: membersReceiptFilter,
    },
    staleTime: 0,
    getNextPageParam: ({ data }) => {
      return (
        data.allArtistMembershipReceipts.pageInfo.hasNextPage && {
          after: data.allArtistMembershipReceipts.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!artistHandle &&
      !!id &&
      (({ pageParam }) => {
        return {
          id,
          artistHandle,
          after: pageParam?.after ?? null,
          first: LIMIT,
          receiptType: membersReceiptFilter,
        };
      }),
    list: ({ allArtistMembershipReceipts }) => {
      return allArtistMembershipReceipts.edges.map(({ node }) => node);
    },
    uniq: ({ id }) => id,
  });

  const artistId = artist?.id;

  const link = useMemo(() => {
    return generateShareLink({
      artistLinkValue: artistHandle,
      path: linkValue ? `/s/${linkValue}` : '/',
      inviteCode: loggedInUser?.adminArtists?.some(adminArtist => adminArtist.artistId === artistId)
        ? null
        : loggedInUser?.inviteCode,
    });
  }, [artistHandle, linkValue, loggedInUser, artistId]);

  const { copy } = useCopy({ successMessage: 'Copied to clipboard', text: link });

  useEffect(() => {
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isAtBottom, isFetchingNextPage]);

  const isOwner =
    !!artistHandle &&
    (loggedInUser?.adminArtists?.some(({ artistLinks }) => artistLinks.includes(artistHandle)) ??
      false);

  const buttons: ActionBottomsheetProps['buttons'] = useMemo(() => {
    const buttonClassName =
      'border-b-vault_text/5 bg-vault_text/10 hover:bg-vault_text/20 text-vault_text ease-in-out duration-300 transition-all md2:h-[45px] text-[16px]/[20px] justify-between gap-4';

    return compact([
      artistHandle != null && {
        label: 'Share',
        trailingIcon: faArrowUpFromBracket,
        type: 'secondary',
        className: buttonClassName,
        href: !isDesktop ? artistNavigationPath(artistHandle, `/s/${linkValue}/share`) : undefined,
        onClick: () => {
          if (isDesktop) {
            openBottomsheet({
              type: BOTTOMSHEET_TYPES.SHARE_DROP,
              shareDropBottomsheetProps: {
                dropSlug: linkValue,
                artistHandle,
                type: 'campaign',
              },
              shared: {
                withVaultTheme: true,
                preventOutsideAutoClose: true,
              },
            });
          } else {
            closeBottomsheet();
          }
        },
      },
      id && {
        label: 'View public page',
        trailingIcon: faGlobe,
        type: 'secondary',
        className: buttonClassName,
        onClick: () => {
          window.open(link, '_blank');
          closeBottomsheet();
        },
      },
      id && {
        label: 'Edit',
        trailingIcon: faPen,
        type: 'secondary',
        className: buttonClassName,
        onClick: () => {
          logInfo({
            action: DROP_INFO_ACTIONS.DROP_EDIT_START,
            message: 'User creating release campaign',
            pillar: PILLARS.DROP,
            data: {
              artistHandle,
              campaignId: id,
            },
          });
          navigate(artistNavigationPath(artistHandle, `/campaign/edit/${id}`));
          closeBottomsheet();
        },
      },
      {
        label: 'Copy link',
        className: buttonClassName,
        trailingIcon: faLink,
        type: 'secondary',
        onClick: () => {
          copy();
          closeBottomsheet();
        },
      },
      id &&
        status && {
          label: status === ReleaseCampaignStatus.Active ? 'Make private' : 'Make public',
          trailingIcon: status === ReleaseCampaignStatus.Active ? faEyeSlash : faEye,
          type: 'secondary',
          className: buttonClassName,
          loading: releaseCampaignDeactivating || releaseCampaignReactivating,
          onClick: async () => {
            openBottomsheet({
              type: 'CONFIRMATION',
              confirmationBottomsheetProps: {
                subText: `Are you sure you want to make this drop ${status === ReleaseCampaignStatus.Active ? 'private' : 'public'}?`,
                confirmButtonText: 'Confirm',
                onConfirm: async () => {
                  status === ReleaseCampaignStatus.Active
                    ? await deactivateCampaign(id)
                    : await reactivateCampaign(id);
                  closeBottomsheet();
                },
              },
            });
          },
        },
      id && {
        label: 'Delete',
        trailingIcon: faTrash,
        type: 'secondary',
        className: buttonClassName,
        loading: releaseCampaignDeleting,
        onClick: async () => {
          logInfo({
            action: DROP_INFO_ACTIONS.DROP_DELETE_START,
            message: 'User deleting release campaign',
            pillar: PILLARS.DROP,
            data: {
              artistHandle,
              campaignId: id,
            },
          });
          openBottomsheet({
            type: 'CONFIRMATION',
            confirmationBottomsheetProps: {
              subText: 'Are you sure you want to delete this drop?',
              confirmButtonText: 'Delete',
              onConfirm: async () => {
                await deleteCampaign(id);
                closeBottomsheet();
                navigate(artistNavigationPath(artistHandle, '/drops'));
              },
            },
          });
        },
      },
    ]);
  }, [
    artistHandle,
    isDesktop,
    linkValue,
    id,
    status,
    releaseCampaignDeactivating,
    releaseCampaignReactivating,
    releaseCampaignDeleting,
    openBottomsheet,
    closeBottomsheet,
    link,
    logInfo,
    navigate,
    copy,
    deactivateCampaign,
    reactivateCampaign,
    deleteCampaign,
  ]);

  const renderItem = useCallback(
    (_index: number, item: (typeof members)[number]) => {
      const {
        id,
        user,
        membership: { vaultSubscription },
        artist,
        createdAt,
      } = item;

      const subscription = getFragment(MemberRowFragmentDoc, vaultSubscription);
      return (
        <MemberRow
          id={id}
          source="subscribers"
          createdAt={vaultSubscription?.createdAt ?? createdAt}
          user={user}
          phone={subscription?.phone ?? null}
          email={subscription?.email ?? null}
          userLocation={subscription?.userLocation ?? null}
          isTrial={subscription?.isTrial ?? null}
          artistMembership={item.membership}
          className="pb-6"
          withVaultTheme
          artist={artist}
          subText={{
            custom: `${
              membersReceiptFilter ===
              ArtistMembershipReceiptTypenames.ArtistMembershipPresaveReceipt
                ? 'Pre-saved'
                : 'Streamed'
            } ${formatDateString({ date: createdAt, format: 'month_day_year' })}`,
          }}
        />
      );
    },
    [membersReceiptFilter],
  );

  const isPresaveOnly = useMemo(
    () => currentState === ReleaseCampaignState.Presave && !!releaseDate && isFuture(releaseDate),
    [currentState, releaseDate],
  );

  const isPresaveActiveFilter = useMemo(
    () => membersReceiptFilter === ArtistMembershipReceiptTypenames.ArtistMembershipPresaveReceipt,
    [membersReceiptFilter],
  );
  const Header = useCallback(() => {
    return (
      <div className="mb-3 flex flex-col gap-5 py-4">
        <InsightHeader campaign={campaign} campaignLink={link} />
        <div className="flex flex-col gap-4">
          <h2 className="font-title text-title-l text-vault_text">Members</h2>
          {!isPresaveOnly && (
            <MembersFilter
              setMembersReceiptFilter={setMembersReceiptFilter}
              membersReceiptFilter={membersReceiptFilter}
            />
          )}
        </div>
      </div>
    );
  }, [campaign, isPresaveOnly, link, membersReceiptFilter]);

  const EmptyState = useCallback(() => {
    if (isInitialLoading) {
      return (
        <div className="flex flex-col gap-6">
          <MemberRowSkeleton />
          <MemberRowSkeleton />
          <MemberRowSkeleton />
        </div>
      );
    }

    if (isError) {
      return (
        <ErrorView
          onRetryClick={refetch}
          loggingType="campaign_insights_page_retry"
          withVaultTheme
          className="h-fit py-10 "
        />
      );
    }

    return (
      <EmptyStateView
        className="mt-8 px-0"
        icon={faReceipt}
        iconClassName="text-vault_text mb-4"
        title={
          isPresaveOnly
            ? 'You currently have no receipts'
            : isPresaveActiveFilter
              ? 'There are no receipts for pre-saves'
              : 'You currently have no receipts'
        }
        titleClassName="font-title !text-title-m  text-vault_text"
        subtitle={
          isPresaveOnly
            ? 'Share this drop with fans'
            : isPresaveActiveFilter
              ? 'Share this drop with fans to get streaming receipts'
              : 'Share this drop with fans'
        }
        buttonText="Share campaign"
        onButtonClick={campaign != null ? copy : undefined}
        withVaultTheme
      />
    );
  }, [isInitialLoading, isError, isPresaveOnly, isPresaveActiveFilter, campaign, copy, refetch]);

  const Footer = useCallback(() => {
    if (isFetchingNextPage && hasNextPage) {
      return (
        <div className="flex items-center justify-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2x"
            className="inline-block animate-spin items-center self-center rounded-full font-medium text-vault_text/50"
          />
        </div>
      );
    }
    if (isLoadingError) {
      return (
        <ErrorView
          onRetryClick={fetchNextPage}
          loggingType="campaign_insights_page_retry"
          withVaultTheme
          className="h-fit py-10"
        />
      );
    }
    return null;
  }, [fetchNextPage, hasNextPage, isFetchingNextPage, isLoadingError]);
  if (loginStatus !== LoginStatus.LOADING && !isOwner) {
    return <Navigate to={artistNavigationPath(artistHandle, '/')} />;
  }

  return (
    <DefaultLayout
      withBottomNavigator={false}
      vaultId={undefined}
      messageChannelId={undefined}
      hasChatReadAccess={undefined}
      showBorder
      showRoundedTop
      withVaultTheme
      stretch
      banner={
        <CampaignStatusBanner
          campaignId={id}
          status={status}
          onMakePublic={() => {
            if (id) reactivateCampaign(id);
          }}
        />
      }
      headerLeft={
        <BackButton
          icon={!isDesktop ? faClose : undefined}
          withVaultTheme
          href={artistNavigationPath(artistHandle, '/drops/events')}
        />
      }
      headerCenter={
        <Text className="font-title text-[18px]/[22px] font-medium text-vault_text">Insights</Text>
      }
      headerRight={
        <Button
          label=""
          iconOnly
          leadingIcon={faEllipsis}
          onClick={() => {
            openBottomsheet({
              type: BOTTOMSHEET_TYPES.ACTION,
              actionBottomsheetProps: {
                buttons,
                className: 'w-full md2:w-80',
                withVaultTheme: true,
              },
            });
          }}
          className="text-[24px] text-vault_text outline-none"
        />
      }
      contentClassName="md2:bg-vault_text/3"
      headerClassName="md2:bg-vault_text/3"
      isHeaderTransparent
    >
      <Virtuoso
        data={members}
        itemContent={renderItem}
        components={{
          Header,
          EmptyPlaceholder: EmptyState,
          Footer,
        }}
        className="h-full w-full overflow-x-hidden"
        endReached={() => {
          fetchNextPage();
        }}
      />
      <div ref={bottomRef} />
    </DefaultLayout>
  );
}

function InsightHeader({
  campaign,
  campaignLink,
}: {
  campaign: FragmentType<CampaignInsightHeaderFragmentDoc>;
  campaignLink: string;
}) {
  const {
    title,
    coverImage,
    initialReleaseImageUrl,
    releaseDate,
    description,
    newSubscriptionCount,
    receiptCount,
    artist,
  } = getFragment(CampaignInsightHeaderFragmentDoc, campaign);

  const coverImageUrl =
    coverImage?.campaignCoverImageUrl ??
    initialReleaseImageUrl ??
    artist.profileImage?.artistFullProfileImageUrl;

  return (
    <View className="flex flex-col items-start justify-start gap-6">
      <View className="flex w-full flex-col items-start justify-start gap-4">
        <button
          className="w-full appearance-none border-none bg-transparent outline-none hover:cursor-pointer focus:outline-none"
          onClick={() => window.open(campaignLink, '_blank')}
        >
          <View className="flex w-full flex-row items-center justify-start gap-2">
            <View
              className={twMerge(
                'flex h-[56px] w-[56px] flex-shrink-0 items-center justify-center rounded-md',
                !!coverImageUrl ? 'bg-transparent' : 'bg-vault_text',
              )}
            >
              {coverImageUrl && (
                <Image
                  src={coverImageUrl}
                  alt="Cover Image"
                  className="h-full w-full rounded-md object-cover"
                />
              )}
            </View>
            <View className="flex flex-col items-start justify-start gap-1">
              <View className="flex items-center">
                <Text className="line-clamp-1   text-left font-title text-[29px] font-medium text-vault_text">
                  {title}
                </Text>
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className="ml-2 text-[18px] text-vault_text"
                />
              </View>
              {releaseDate != null && (
                <Text className="font-base text-[14px]/[18px] font-normal text-vault_text opacity-60">
                  {formatDateString({ date: releaseDate, format: 'month_day_year' })}
                </Text>
              )}
            </View>
          </View>
        </button>
        {!!description && (
          <Text className="font-base text-[14px]/[18px] font-normal text-vault_text opacity-50">
            {description}
          </Text>
        )}
      </View>
      <View className="flex w-full flex-row items-center justify-start gap-2">
        <View className="flex flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10 p-3">
          <Text className="font-title text-[32px] font-medium text-vault_text">
            {millify(receiptCount, { lowercase: true })}
          </Text>
          <Text className="text-vault_text">Total receipts</Text>
        </View>
        <View className="flex flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10 p-3">
          <Text className="font-title text-[32px] font-medium text-vault_text">
            {millify(newSubscriptionCount, { lowercase: true })}
          </Text>
          <Text className="text-vault_text">Total signups</Text>
        </View>
      </View>
    </View>
  );
}

const MembersFilter = ({
  setMembersReceiptFilter,
  membersReceiptFilter,
}: {
  setMembersReceiptFilter: React.Dispatch<React.SetStateAction<ArtistMembershipReceiptTypenames>>;
  membersReceiptFilter: ArtistMembershipReceiptTypenames;
}) => {
  return (
    <div className="flex gap-2">
      <FilterButton
        active={
          membersReceiptFilter === ArtistMembershipReceiptTypenames.ArtistMembershipPresaveReceipt
        }
        label="Presaves"
        onClick={() =>
          setMembersReceiptFilter(ArtistMembershipReceiptTypenames.ArtistMembershipPresaveReceipt)
        }
      />
      <FilterButton
        label="Streams"
        active={
          membersReceiptFilter ===
          ArtistMembershipReceiptTypenames.ArtistMembershipPlayStreamReceipt
        }
        onClick={() =>
          setMembersReceiptFilter(
            ArtistMembershipReceiptTypenames.ArtistMembershipPlayStreamReceipt,
          )
        }
      />
    </div>
  );
};

const FilterButton = ({
  label,
  active,
  onClick,
}: {
  label: string;
  active: boolean;
  onClick: () => void;
}) => {
  return (
    <button
      data-state={active ? 'active' : 'inactive'}
      type="button"
      className="w-full flex-shrink cursor-pointer appearance-none rounded-full border px-4 py-3 font-title text-title-s outline-none transition-all duration-150 ease-in data-[state=active]:border-transparent data-[state=inactive]:border-vault_text/30 data-[state=active]:bg-vault_text/80 data-[state=inactive]:bg-transparent data-[state=active]:text-vault_text_opposite data-[state=inactive]:text-vault_text/30 data-[state=inactive]:hover:bg-vault_text/10  "
      onClick={onClick}
    >
      {label}
    </button>
  );
};
