import { useEffect, useState } from 'react';
import { captureException, captureMessage } from '@sentry/react';
import { isEqual } from 'lodash-es';
import { useNavigate } from 'react-router';
import { subscribe, useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { ENVIRONMENT } from '@soundxyz/utils/src/const';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  ArtistEventByIdDocument,
  ArtistEventsDocument,
  CampaignInsightHeaderFragmentDoc,
  CampaignInsightsDocument,
  CreateRsvpEventDocument,
  DeactivateRsvpEventDocument,
  DeleteRsvpEventDocument,
  GetPaginatedVaultAnnouncementsDocument,
  LastMembershipReceiptDocument,
  type MutationCreateRsvpEventInput,
  type MutationDeactivateRsvpEventInput,
  type MutationDeleteRsvpEventInput,
  type MutationReactivateRsvpEventInput,
  type MutationUpdateRsvpEventInput,
  ReactivateRsvpEventDocument,
  RsvpEventByIdDocument,
  RsvpToEventDocument,
  UpdateRsvpEventDocument,
  UserArtistMembershipDocument,
  UserArtistReceiptsDocument,
} from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useWindow } from '../../hooks/useWindow';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { uploadMultipartFile } from '../../utils/s3Utils';
import { PersistenceStorage } from '../../utils/storeUtils';
import {
  clearErrors,
  clearFields,
  populateFields,
  setError,
  setField,
  setFieldsToUndefined,
  validateField,
} from './helper';
import { RSVPEventSteps, RsvpFieldSchema } from './schema';
import { initialRsvpState, rsvpState } from './store';

gql(/* GraphQL */ `
  mutation CreateRSVPEvent($input: MutationCreateRsvpEventInput!) {
    createRsvpEvent(input: $input) {
      __typename

      ... on MutationCreateRsvpEventSuccess {
        data {
          id
          linkValue
          ...RsvpEventCreated
        }
      }

      ... on Error {
        message
      }
    }
  }

  mutation UpdateRSVPEvent($input: MutationUpdateRsvpEventInput!) {
    updateRsvpEvent(input: $input) {
      __typename
      ... on MutationUpdateRsvpEventSuccess {
        data {
          id
        }
      }

      ... on Error {
        message
      }
    }
  }

  mutation DeleteRsvpEvent($input: MutationDeleteRsvpEventInput!) {
    deleteRsvpEvent(input: $input) {
      __typename
      ... on MutationDeleteRsvpEventSuccess {
        data {
          id
        }
      }

      ... on Error {
        message
      }
    }
  }

  mutation DeactivateRsvpEvent($input: MutationDeactivateRsvpEventInput!) {
    deactivateRsvpEvent(input: $input) {
      __typename
      ... on MutationDeactivateRsvpEventSuccess {
        data {
          id
        }
      }

      ... on Error {
        message
      }
    }
  }

  mutation ReactivateRsvpEvent($input: MutationReactivateRsvpEventInput!) {
    reactivateRsvpEvent(input: $input) {
      __typename
      ... on MutationReactivateRsvpEventSuccess {
        data {
          id
        }
      }

      ... on Error {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [DeactivateRsvpEventDocument, ReactivateRsvpEventDocument],
  refetch: [CampaignInsightsDocument, ArtistEventByIdDocument],
});

RefetchOnComplete({
  trigger: [RsvpToEventDocument],
  refetch: [
    RsvpEventByIdDocument,
    LastMembershipReceiptDocument,
    UserArtistMembershipDocument,
    ArtistEventsDocument,
    UserArtistReceiptsDocument,
  ],
});

const version = '0.1';
const storageKey = `@vault/campaign${ENVIRONMENT === 'production' ? '' : '-' + ENVIRONMENT}-${version}`;

const RsvpPersistence = PersistenceStorage({
  schema: RsvpFieldSchema.partial(),
  key: storageKey,
  eager: true,
});

if (typeof window !== 'undefined') {
  RsvpPersistence.initialValue
    .then(value => {
      if (!value) return;
      Object.assign(rsvpState.fields, value);
    })
    .catch(
      // eslint-disable-next-line no-console
      console.error,
    )
    .finally(() => {
      subscribe(
        rsvpState.fields,
        () => {
          if (isEqual(rsvpState.fields, initialRsvpState().fields)) return;
          const pendingStorageSet = setTimeout(() => {
            const data = setFieldsToUndefined(rsvpState.fields);
            const parseToStore = RsvpFieldSchema.partial().safeParse(data);

            if (parseToStore.success) {
              RsvpPersistence.set(parseToStore.data);
            }
            clearTimeout(pendingStorageSet);
          }, 0);
        },
        true,
      );
    });
}

RefetchOnComplete({
  trigger: [
    CreateRsvpEventDocument,
    UpdateRsvpEventDocument,
    DeleteRsvpEventDocument,
    DeactivateRsvpEventDocument,
    ReactivateRsvpEventDocument,
  ],
  refetch: [
    ArtistEventsDocument,
    CampaignInsightHeaderFragmentDoc,
    ArtistEventsDocument,
    GetPaginatedVaultAnnouncementsDocument,
  ],
});

export const useRsvpEventForm = () => {
  const { isDesktop } = useWindow();

  const { fields, errors } = useSnapshot(rsvpState, {
    sync: true,
  });
  const { artistHandle } = useArtistHandle();
  const { openBottomsheet } = useBottomsheetContainer();
  const { openToast } = useToast();
  const navigate = useNavigate();

  const hasError = Object.values(errors).some(error => error !== null);

  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isSetupComplete, setIsSetupComplete] = useState(false);
  const [isReleaseComplete, setIsReleaseComplete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutateAsync: creatEvent, isLoading: rsvpEventCreationLoading } = useMutation(
    CreateRsvpEventDocument,
    {},
  );
  const { mutateAsync: updateEvent, isLoading: rsvpEventUpdateLoading } = useMutation(
    UpdateRsvpEventDocument,
    {},
  );
  const { mutateAsync: deleteEvent, isLoading: rsvpEventDeleting } = useMutation(
    DeleteRsvpEventDocument,
    {},
  );
  const { mutateAsync: deactivateEvent, isLoading: rsvpEventDeactivating } = useMutation(
    DeactivateRsvpEventDocument,
    {},
  );
  const { mutateAsync: reactivateEvent, isLoading: rsvpEventReactivating } = useMutation(
    ReactivateRsvpEventDocument,
    {},
  );

  const enableSubmit =
    !hasError && isSetupComplete && isReleaseComplete && !rsvpEventCreationLoading;

  useEffect(() => {
    const validThankyouMessage = fields.shouldSendThankyouMessage
      ? fields.thankyouMessage.trim().length > 0 && !errors.thankyouMessage
      : true;
    setIsSetupComplete(!!fields.title && validThankyouMessage);
  }, [
    errors.thankyouMessage,
    fields.shouldSendThankyouMessage,
    fields.thankyouMessage,
    fields.title,
  ]);

  useEffect(() => {
    const isDateInFuture = fields.eventDate ? fields.eventDate > new Date() : true;
    const validMessage =
      fields.canEditSms && fields.shouldSendSms
        ? fields.message?.trim().length > 0 &&
          isDateInFuture &&
          !!fields.eventDate &&
          !errors.message
        : true;
    setIsReleaseComplete(validMessage);
  }, [fields.shouldSendSms, fields.message, fields.eventDate, errors.message, fields.canEditSms]);

  const clearAllFields = () => {
    RsvpPersistence.clear();
    clearFields();
  };

  const determineNextStep = (currentStep: RSVPEventSteps): RSVPEventSteps | null => {
    // Create flow
    switch (currentStep) {
      case RSVPEventSteps.Intro:
        return RSVPEventSteps.Setup;
      case RSVPEventSteps.Setup:
        return RSVPEventSteps.Release;
      case RSVPEventSteps.Release:
        return RSVPEventSteps.Preview;
      case RSVPEventSteps.Preview:
        return null; // This is the last step
      default:
        return currentStep;
    }
  };

  const determinePrevStep = (currentStep: RSVPEventSteps): RSVPEventSteps | null => {
    // Create flow
    switch (currentStep) {
      case RSVPEventSteps.Intro:
      case RSVPEventSteps.Setup:
        return null; // This is the first step
      case RSVPEventSteps.Release:
        return RSVPEventSteps.Setup;
      case RSVPEventSteps.Preview:
        return RSVPEventSteps.Release;
      default:
        return currentStep;
    }
  };

  const onSubmit = async (artistId: string) => {
    if (!enableSubmit || !artistId || !artistHandle) return;

    setIsSubmitting(true);
    const input = {
      artistId,
      title: fields.title,
      coverImageId: fields.mediaId ? fields.mediaId : undefined,
      description: fields.description || '',
      eventDate:
        fields.shouldSendSms && fields.eventDate ? fields.eventDate.toISOString() : undefined,
      thankYouMessage:
        fields.shouldSendThankyouMessage && fields.thankyouMessage
          ? fields.thankyouMessage
          : undefined,
      releaseAnnouncement:
        fields.message && fields.shouldSendSms && fields.eventDate
          ? {
              date: fields.eventDate?.toISOString(),
              message: fields.message,
              target: fields.sendToGroup,
            }
          : undefined,
    } satisfies MutationCreateRsvpEventInput;

    try {
      const { data } = await creatEvent({ input });
      if (data.createRsvpEvent.__typename === 'ValidationError') {
        openToast({
          text: 'Validation error',
          variant: 'error',
        });
        captureMessage('RSVP Drop - Validation Error', {
          level: 'error',
        });
      }

      if (data.createRsvpEvent.__typename === 'NotFoundError') {
        openToast({
          text: 'Not found error',
          variant: 'error',
        });
        captureMessage('RSVP Drop - Not Found', {
          level: 'error',
        });
      }

      if (data.createRsvpEvent.__typename === 'MutationCreateRsvpEventSuccess') {
        trackEvent({
          type: EVENTS.CREATE_RSVP_DROP,
          properties: {
            artistHandle,
          },
        });
        navigate(artistNavigationPath(artistHandle, '/drops'));
        if (isDesktop) {
          openBottomsheet({
            type: BOTTOMSHEET_TYPES.SHARE_DROP,
            shared: {
              withVaultTheme: true,
              preventOutsideAutoClose: true,
            },
            shareDropBottomsheetProps: {
              dropSlug: data.createRsvpEvent.data.linkValue,
              artistHandle,
              type: 'rsvp',
            },
          });
        } else {
          navigate(
            artistNavigationPath(artistHandle, `/d/${data.createRsvpEvent.data.linkValue}/share`),
          );
        }
        clearAllFields();
      }
    } catch (error) {
      captureException(error, { tags: { feature: 'Create RSVP Drop', artistHandle } });
      openToast({
        text: 'There was an error creating the RSVP drop',
        variant: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onUpdate = async (dropId: string) => {
    if (!enableSubmit || !artistHandle) return;

    setIsSubmitting(true);

    const input = {
      rsvpEventId: dropId,
      title: fields.title,
      coverImageId: fields.mediaId ? fields.mediaId : null,
      description: fields.description || '',
      eventDate: fields.eventDate ? fields.eventDate.toISOString() : undefined,
      thankYouMessage:
        fields.shouldSendThankyouMessage && fields.thankyouMessage ? fields.thankyouMessage : null,
      releaseAnnouncement:
        fields.message && fields.shouldSendSms && fields.eventDate && fields.sendToGroup
          ? {
              date: fields.eventDate?.toISOString(),
              message: fields.message,
              target: fields.sendToGroup,
            }
          : null,
    } satisfies MutationUpdateRsvpEventInput;

    try {
      const { data } = await updateEvent({ input });

      if (data.updateRsvpEvent.__typename === 'ValidationError') {
        openToast({
          text: data.updateRsvpEvent.message,
          variant: 'error',
        });
        captureMessage('Update RSVP Drop - Validation Error', {
          level: 'error',
        });
      }

      if (data.updateRsvpEvent.__typename === 'NotFoundError') {
        openToast({
          text: data.updateRsvpEvent.message,
          variant: 'error',
        });
        captureMessage('Update RSVP Drop - Not Found', {
          level: 'error',
        });
      }

      if (data.updateRsvpEvent.__typename === 'MutationUpdateRsvpEventSuccess') {
        trackEvent({
          type: EVENTS.UPDATE_RSVP_DROP,
          properties: {
            artistHandle,
          },
        });
        navigate(artistNavigationPath(artistHandle, '/'));
        openToast({
          text: 'RSVP successfully updated.',
          variant: 'success',
        });
        clearAllFields();
      }
    } catch (error) {
      captureException(error, { tags: { feature: 'Update RSVP Drop', artistHandle } });
      openToast({
        text: 'There was an error updating the RSVP drop',
        variant: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const uploadRsvpEventImage = async (artistId: string, file: File) => {
    setIsUploading(true);
    try {
      const { mediaId, cdnUrl } = await uploadMultipartFile({
        file,
        mediaType: 'IMAGE',
        setProgress: bytes => {
          const progress = Math.floor((bytes / file.size) * 100);
          setProgress(progress);
        },
        artistId,
      });
      setField('mediaId', mediaId);
      setField('image', cdnUrl);
    } catch (error) {
      captureException(error, {
        tags: {
          selectedFileName: file.name,
          selectedFileSize: file.size,
          selectedFileType: file.type,
          feature: 'useRsvpEventForm',
        },
      });
      openToast({
        text: `There was an error uploading your image. ${error}`,
        variant: 'error',
      });
    } finally {
      setProgress(0);
      setIsUploading(false);
    }
  };

  const deleteRsvpEvent = async (eventId: string) => {
    const input = {
      rsvpEventId: eventId,
    } satisfies MutationDeleteRsvpEventInput;

    try {
      const { data } = await deleteEvent({ input });
      if (data.deleteRsvpEvent.__typename === 'MutationDeleteRsvpEventSuccess') {
        openToast({
          text: 'RSVP drop successfully deleted.',
          variant: 'success',
        });
      }
    } catch (error) {
      captureException(error, { tags: { feature: 'Delete RSVP drop', artistHandle } });
      openToast({
        text: 'There was an error deleting the rsvp drop',
        variant: 'error',
      });
    }
  };

  const deactivateRsvpEvent = async (eventId: string) => {
    const input = {
      rsvpEventId: eventId,
    } satisfies MutationDeactivateRsvpEventInput;
    try {
      const { data } = await deactivateEvent({ input });
      if (data.deactivateRsvpEvent.__typename === 'MutationDeactivateRsvpEventSuccess') {
        openToast({
          text: 'RSVP drop has been ended.',
          variant: 'success',
        });
      }
    } catch (error) {
      captureException(error, { tags: { feature: 'Deactivate RSVP drop', artistHandle } });
      openToast({
        text: 'There was an error ending the rsvp drop',
        variant: 'error',
      });
    }
  };

  const reactivateRsvpEvent = async (eventId: string) => {
    const input = {
      rsvpEventId: eventId,
    } satisfies MutationReactivateRsvpEventInput;
    try {
      const { data } = await reactivateEvent({ input });
      if (data.reactivateRsvpEvent.__typename === 'MutationReactivateRsvpEventSuccess') {
        openToast({
          text: 'RSVP drop is now public.',
          variant: 'success',
        });
      }
    } catch (error) {
      captureException(error, { tags: { feature: 'Deactivate RSVP drop', artistHandle } });
      openToast({
        text: 'There was an error deactivating the rsvp drop',
        variant: 'error',
      });
    }
  };

  return {
    fields,
    errors,
    enableSubmit,
    isUploading,
    isSubmitting,
    progress,
    isSetupComplete,
    isReleaseComplete,
    rsvpEventCreationLoading,
    rsvpEventUpdateLoading,
    rsvpEventDeleting,
    rsvpEventDeactivating,
    rsvpEventReactivating,
    deleteRsvpEvent,
    deactivateRsvpEvent,
    reactivateRsvpEvent,
    validateField,
    determineNextStep,
    determinePrevStep,
    uploadRsvpEventImage,
    onSubmit,
    onUpdate,
    clearAllFields,
    clearFields,
    clearErrors,
    populateFields,
    setField,
    setError,
  };
};
