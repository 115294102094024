import { useEffect, useState } from 'react';
import { isEqual } from 'lodash-es';
import { useNavigate } from 'react-router';
import { subscribe, useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { ENVIRONMENT } from '@soundxyz/utils/src/const';
import {
  DROP_ERROR_ACTIONS,
  DROP_INFO_ACTIONS,
  ERROR_TYPE,
  PILLARS,
} from '@soundxyz/vault-logs-utils/dist/constants';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  AppleMusicPresaveDocument,
  ArtistEventByIdDocument,
  CampaignInsightsDocument,
  CreateReleaseCampaignDocument,
  DeactivateReleaseCampaignDocument,
  DeleteReleaseCampaignDocument,
  type MutationCreateReleaseCampaignInput,
  type MutationDeactivateReleaseCampaignInput,
  type MutationDeleteReleaseCampaignInput,
  type MutationReactivateReleaseCampaignInput,
  type MutationUpdateReleaseCampaignInput,
  ReactivateReleaseCampaignDocument,
  ReleaseCampaignReportPlayStreamDocument,
  SpotifyPresaveDocument,
  ThirdPartyPlatform,
  UpdateReleaseCampaignDocument,
  UserArtistMembershipDocument,
} from '../../graphql/generated';
import { useLogError } from '../../hooks/logger/useLogError';
import { useLogInfo } from '../../hooks/logger/useLogInfo';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useWindow } from '../../hooks/useWindow';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import {
  extractAppleMusicResourceId,
  extractSpotifyResourceIdFromUrl,
} from '../../utils/linkUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { uploadMultipartFile } from '../../utils/s3Utils';
import { PersistenceStorage } from '../../utils/storeUtils';
import {
  clearErrors,
  clearFields,
  populateFields,
  setError,
  setField,
  setFieldsToUndefined,
  validateField,
} from './helpers';
import { CampaignFieldSchema, CampaignSteps, CampaignType } from './schema';
import { campaignState, initialCampaignState } from './store';

gql(/* GraphQL */ `
  mutation CreateReleaseCampaign($input: MutationCreateReleaseCampaignInput!) {
    createReleaseCampaign(input: $input) {
      __typename
      ... on MutationCreateReleaseCampaignSuccess {
        data {
          id
          linkValue
          ...EventCreated
        }
      }

      ... on Error {
        message
      }
    }
  }

  mutation UpdateReleaseCampaign($input: MutationUpdateReleaseCampaignInput!) {
    updateReleaseCampaign(input: $input) {
      __typename
      ... on MutationUpdateReleaseCampaignSuccess {
        data {
          id
          linkValue
        }
      }

      ... on Error {
        message
      }
    }
  }

  mutation DeleteReleaseCampaign($input: MutationDeleteReleaseCampaignInput!) {
    deleteReleaseCampaign(input: $input) {
      __typename
      ... on MutationDeleteReleaseCampaignSuccess {
        data {
          id
        }
      }

      ... on Error {
        message
      }
    }
  }

  mutation DeactivateReleaseCampaign($input: MutationDeactivateReleaseCampaignInput!) {
    deactivateReleaseCampaign(input: $input) {
      __typename
      ... on MutationDeactivateReleaseCampaignSuccess {
        data {
          id
        }
      }

      ... on Error {
        message
      }
    }
  }

  mutation ReactivateReleaseCampaign($input: MutationReactivateReleaseCampaignInput!) {
    reactivateReleaseCampaign(input: $input) {
      __typename
      ... on MutationReactivateReleaseCampaignSuccess {
        data {
          id
        }
      }

      ... on Error {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [DeactivateReleaseCampaignDocument, ReactivateReleaseCampaignDocument],
  refetch: [CampaignInsightsDocument, ArtistEventByIdDocument],
});

RefetchOnComplete({
  trigger: [
    ReleaseCampaignReportPlayStreamDocument,
    SpotifyPresaveDocument,
    AppleMusicPresaveDocument,
  ],
  refetch: [UserArtistMembershipDocument],
});

const version = '0.1';
const storageKey = `@vault/campaign${ENVIRONMENT === 'production' ? '' : '-' + ENVIRONMENT}-${version}`;

const CampaignPersistence = PersistenceStorage({
  schema: CampaignFieldSchema.partial(),
  key: storageKey,
  eager: true,
});

if (typeof window !== 'undefined') {
  CampaignPersistence.initialValue
    .then(value => {
      if (!value) return;
      Object.assign(campaignState.fields, value);
    })
    .catch(
      // eslint-disable-next-line no-console
      console.error,
    )
    .finally(() => {
      subscribe(
        campaignState.fields,
        () => {
          if (isEqual(campaignState.fields, initialCampaignState().fields)) return;
          const pendingStorageSet = setTimeout(() => {
            const data = setFieldsToUndefined(campaignState.fields);
            const parseToStore = CampaignFieldSchema.partial().safeParse(data);

            if (parseToStore.success) {
              CampaignPersistence.set(parseToStore.data);
            }
            clearTimeout(pendingStorageSet);
          }, 0);
        },
        true,
      );
    });
}

const pillar = PILLARS.DROP;

export const useCampaignForm = () => {
  const { isDesktop } = useWindow();
  const { fields, errors } = useSnapshot(campaignState, {
    sync: true,
  });

  const logInfo = useLogInfo();
  const logError = useLogError();

  useEffect(() => {
    setField('shouldSendSms', false);
    setField('message', '');
  }, []);

  const { artistHandle } = useArtistHandle();
  const { openBottomsheet } = useBottomsheetContainer();
  const { openToast } = useToast();
  const navigate = useNavigate();

  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isSetupComplete, setIsSetupComplete] = useState(false);
  const [isLinksComplete, setIsLinksComplete] = useState(false);
  const [isReleaseComplete, setIsReleaseComplete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutateAsync: createEvent, isLoading: releaseCampaignCreationLoading } = useMutation(
    CreateReleaseCampaignDocument,
    {},
  );
  const { mutateAsync: updateEvent, isLoading: releaseCampaignUpdateLoading } = useMutation(
    UpdateReleaseCampaignDocument,
    {},
  );
  const { mutateAsync: deleteEvent, isLoading: releaseCampaignDeleting } = useMutation(
    DeleteReleaseCampaignDocument,
    {},
  );
  const { mutateAsync: deactivateEvent, isLoading: releaseCampaignDeactivating } = useMutation(
    DeactivateReleaseCampaignDocument,
    {},
  );
  const { mutateAsync: reactivateEvent, isLoading: releaseCampaignReactivating } = useMutation(
    ReactivateReleaseCampaignDocument,
    {},
  );

  const isPresave = fields.campaignType === CampaignType.Presave;

  const hasError = Object.values(errors).some(error => error !== null);

  const enableSubmit =
    !hasError &&
    !isSubmitting &&
    isSetupComplete &&
    isLinksComplete &&
    isReleaseComplete &&
    !releaseCampaignCreationLoading;

  useEffect(() => {
    setIsSetupComplete(
      !!fields.title &&
        !!fields.artist &&
        (fields.campaignType !== CampaignType.Presave ||
          (!!fields.releaseDate && !errors.releaseDate)),
    );
  }, [
    fields.image,
    fields.title,
    fields.artist,
    fields.mediaId,
    fields.thirdPartyImageUrl,
    errors.releaseDate,
    fields.releaseDate,
    fields.campaignType,
  ]);

  useEffect(() => {
    const isLinkComplete = () => {
      if (!fields.campaignType || errors.contentType) return false;

      const validDSPs = fields.dsps.filter(dsp => dsp.uri !== '' && dsp.showLink);
      const hasInvalidURIs = fields.dsps.some(
        dsp => dsp.uri !== '' && errors.dsps?.some(e => e?.uri),
      );

      if (isPresave) {
        const hasEnabledPresave = fields.showAppleMusicPresave || fields.showSpotifyPresave;
        return hasEnabledPresave === true && !hasInvalidURIs;
      } else if (fields.campaignType === CampaignType.Stream) {
        return validDSPs.length > 0 && !hasInvalidURIs;
      }

      return false;
    };

    setIsLinksComplete(isLinkComplete());
  }, [
    fields.dsps,
    fields.campaignType,
    errors.dsps,
    errors.contentType,
    isPresave,
    fields.showAppleMusicPresave,
    fields.showSpotifyPresave,
  ]);

  useEffect(() => {
    const validMessage = fields.shouldSendSms
      ? fields.message?.trim().length > 0 && !!fields.announcementDate && !errors.message
      : true;

    const validReleaseMessage = isPresave
      ? fields.shouldSendReleaseSms
        ? fields.releaseMessage?.trim().length > 0 && !!fields.releaseDate && !errors.releaseMessage
        : true
      : true;

    setIsReleaseComplete(validMessage && validReleaseMessage);
  }, [
    fields.shouldSendSms,
    fields.shouldSendReleaseSms,
    fields.message,
    fields.releaseMessage,
    fields.announcementDate,
    fields.releaseDate,
    isPresave,
    errors.releaseMessage,
    errors.message,
  ]);

  const clearAllFields = () => {
    CampaignPersistence.clear();
    clearFields();
  };

  const determineNextStep = (currentStep: CampaignSteps): CampaignSteps | null => {
    if (fields.isEditMode) {
      switch (currentStep) {
        case CampaignSteps.Setup:
          return isPresave
            ? CampaignSteps.PresavePrereleaseLinks
            : CampaignSteps.StreamReleaseLinks;
        case CampaignSteps.PresavePrereleaseLinks:
          return CampaignSteps.PresaveReleaseLinks;
        case CampaignSteps.PresaveReleaseLinks:
        case CampaignSteps.StreamReleaseLinks:
          return !isPresave ? CampaignSteps.Preview : CampaignSteps.Release;
        case CampaignSteps.Release:
          return CampaignSteps.Preview;
        case CampaignSteps.Preview:
          return null; // This is the last step
        default:
          return currentStep;
      }
    } else {
      // Create flow
      switch (currentStep) {
        case CampaignSteps.Intro:
          return CampaignSteps.Scan;
        case CampaignSteps.Scan:
          return CampaignSteps.Setup;
        case CampaignSteps.Setup:
          return isPresave
            ? CampaignSteps.PresavePrereleaseLinks
            : CampaignSteps.StreamReleaseLinks;
        case CampaignSteps.PresavePrereleaseLinks:
          return CampaignSteps.PresaveReleaseLinks;
        case CampaignSteps.PresaveReleaseLinks:
        case CampaignSteps.StreamReleaseLinks:
          return !isPresave ? CampaignSteps.Preview : CampaignSteps.Release;
        case CampaignSteps.Release:
          return CampaignSteps.Preview;
        case CampaignSteps.Preview:
          return null; // This is the last step
        default:
          return currentStep;
      }
    }
  };

  const determinePrevStep = (currentStep: CampaignSteps): CampaignSteps | null => {
    if (fields.isEditMode) {
      switch (currentStep) {
        case CampaignSteps.Intro:
        case CampaignSteps.Setup:
          return null; // This is the first step in edit mode
        case CampaignSteps.PresavePrereleaseLinks:
        case CampaignSteps.StreamReleaseLinks:
          return CampaignSteps.Setup;
        case CampaignSteps.PresaveReleaseLinks:
          return CampaignSteps.PresavePrereleaseLinks;
        case CampaignSteps.Release:
          return isPresave ? CampaignSteps.PresaveReleaseLinks : CampaignSteps.StreamReleaseLinks;
        case CampaignSteps.Preview:
          return isPresave ? CampaignSteps.Release : CampaignSteps.StreamReleaseLinks;
        default:
          return currentStep;
      }
    } else {
      // Create flow
      switch (currentStep) {
        case CampaignSteps.Intro:
        case CampaignSteps.Setup:
          return null; // This is the first step
        case CampaignSteps.Scan:
          return CampaignSteps.Intro;
        case CampaignSteps.PresavePrereleaseLinks:
          return CampaignSteps.Setup;
        case CampaignSteps.PresaveReleaseLinks:
          return CampaignSteps.PresavePrereleaseLinks;
        case CampaignSteps.StreamReleaseLinks:
          return CampaignSteps.Setup;
        case CampaignSteps.Release:
          return isPresave ? CampaignSteps.PresaveReleaseLinks : CampaignSteps.StreamReleaseLinks;
        case CampaignSteps.Preview:
          return isPresave ? CampaignSteps.Release : CampaignSteps.StreamReleaseLinks;
        default:
          return currentStep;
      }
    }
  };

  const onSubmit = async () => {
    if (!enableSubmit || !artistHandle) return;

    setIsSubmitting(true);

    const input = {
      artistHandle,
      announcement:
        fields.message && fields.shouldSendSms
          ? {
              date: fields.announcementDate?.toISOString() ?? new Date().toISOString(),
              message: fields.message,
            }
          : undefined,
      contentType: fields.contentType,
      coverImageMediaId: fields.image && fields.mediaId ? fields.mediaId : undefined,
      thirdPartyReleaseImageUrl: fields.thirdPartyImageUrl,
      description: fields.description || undefined,
      isrc: fields.isrc || undefined,
      upc: fields.upc || undefined,
      shouldFindAppleMusicLink: fields.dsps.some(
        dsp => dsp.key === ThirdPartyPlatform.AppleMusic && dsp.uri === '',
      ),
      shouldFindSpotifyLink: fields.dsps.some(
        dsp => dsp.key === ThirdPartyPlatform.Spotify && dsp.uri === '',
      ),
      showSpotifyPresaveFirst: fields.showSpotifyPresaveFirst,
      showAppleMusicPresave: fields.showAppleMusicPresave,
      showSpotifyPresave: fields.showSpotifyPresave,
      externalLinks: fields.dsps
        .filter(dsp => dsp.uri !== '')
        .map((dsp, index) => ({
          cta:
            dsp.buttonText ||
            (dsp.buttonText === '' || dsp.buttonText === null ? 'Play' : dsp.buttonText),
          enabled: dsp.showLink,
          order: index,
          platform: dsp.key,
          url: dsp.uri,
        })),
      presavesEnabled: isPresave,
      releaseDate: fields.releaseDate ? fields.releaseDate.toISOString() : undefined,
      releaseMessage: fields.shouldSendReleaseSms ? fields.releaseMessage : undefined,
      title: fields.title,
      appleMusicResourceId: extractAppleMusicResourceId(
        fields.dsps.find(dsp => dsp.key === ThirdPartyPlatform.AppleMusic)?.uri,
      ),
      spotifyResourceId: extractSpotifyResourceIdFromUrl(
        fields.dsps.find(dsp => dsp.key === ThirdPartyPlatform.Spotify)?.uri,
      ),
    } satisfies MutationCreateReleaseCampaignInput;

    try {
      const { data } = await createEvent({ input });

      if (data.createReleaseCampaign.__typename === 'ValidationError') {
        logError({
          action: DROP_ERROR_ACTIONS.DROP_VALIDATION_ERROR,
          error: new Error(data.createReleaseCampaign.message),
          level: 'warning',
          message: data.createReleaseCampaign.message,
          errorType: ERROR_TYPE.MUTATION_ERROR,
          pillar,
          indexedTags: {
            source: 'useCampaignForm',
          },
          openToast,
          toast: data.createReleaseCampaign.message,
        });
      }

      if (data.createReleaseCampaign.__typename === 'NotFoundError') {
        logError({
          action: DROP_ERROR_ACTIONS.DROP_ERROR,
          error: new Error(data.createReleaseCampaign.message),
          level: 'error',
          message: data.createReleaseCampaign.message,
          errorType: ERROR_TYPE.NOT_FOUND_ERROR,
          pillar,
          indexedTags: {
            source: 'useCampaignForm',
          },
          openToast,
          toast: data.createReleaseCampaign.message,
        });
      }

      if (data.createReleaseCampaign.__typename === 'MutationCreateReleaseCampaignSuccess') {
        trackEvent({
          type: EVENTS.CREATE_RELEASE_CAMPAIGN,
          properties: {
            artistHandle,
            presaveEnabled: isPresave,
          },
        });
        logInfo({
          action: DROP_INFO_ACTIONS.DROP_CREATE_SUCCESS,
          message: 'Drop created successfully',
          pillar,
          data: {
            artistHandle,
            type: isPresave ? 'pre-save' : 'stream',
            campaignId: data.createReleaseCampaign.data.id,
          },
        });
        navigate(artistNavigationPath(artistHandle, '/drops'));

        if (isDesktop) {
          openBottomsheet({
            type: BOTTOMSHEET_TYPES.SHARE_DROP,
            shared: {
              withVaultTheme: true,
              preventOutsideAutoClose: true,
            },
            shareDropBottomsheetProps: {
              dropSlug: data.createReleaseCampaign.data.linkValue,
              artistHandle,
              type: 'campaign',
            },
          });
        } else {
          navigate(
            artistNavigationPath(
              artistHandle,
              `/s/${data.createReleaseCampaign.data.linkValue}/share`,
            ),
          );
        }

        setTimeout(() => {
          clearAllFields();
        }, 5000);
      }
    } catch (error) {
      logError({
        action: DROP_ERROR_ACTIONS.DROP_ERROR,
        error,
        level: 'fatal',
        message: 'There was an error creating the drop',
        errorType: ERROR_TYPE.MUTATION_ERROR,
        pillar,
        indexedTags: {
          source: 'useCampaignForm',
        },
        openToast,
        toast: 'There was an error creating the drop.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateCampaign = async ({
    input,
    setIsUpdating,
    onSuccess,
  }: {
    input: MutationUpdateReleaseCampaignInput;
    setIsUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
    onSuccess?: () => void;
  }) => {
    setIsUpdating?.(true);

    try {
      const { data } = await updateEvent({ input });

      if (data.updateReleaseCampaign.__typename === 'ValidationError') {
        logError({
          action: DROP_ERROR_ACTIONS.DROP_EDIT_VALIDATION_ERROR,
          error: new Error(data.updateReleaseCampaign.message),
          level: 'warning',
          message: data.updateReleaseCampaign.message,
          errorType: ERROR_TYPE.VALIDATION_ERROR,
          pillar,
          indexedTags: {
            source: 'useCampaignForm',
          },
          openToast,
          toast: data.updateReleaseCampaign.message,
        });
      }

      if (data.updateReleaseCampaign.__typename === 'NotFoundError') {
        logError({
          action: DROP_ERROR_ACTIONS.DROP_EDIT_VALIDATION_ERROR,
          error: new Error(data.updateReleaseCampaign.message),
          level: 'error',
          message: data.updateReleaseCampaign.message,
          errorType: ERROR_TYPE.NOT_FOUND_ERROR,
          pillar,
          indexedTags: {
            source: 'useCampaignForm',
          },
          openToast,
          toast: data.updateReleaseCampaign.message,
        });
      }

      if (data.updateReleaseCampaign.__typename === 'MutationUpdateReleaseCampaignSuccess') {
        logInfo({
          action: DROP_INFO_ACTIONS.DROP_EDIT_SUCCESS,
          message: 'Drop created successfully',
          pillar,
          data: {
            artistHandle,
            type: isPresave ? 'pre-save' : 'stream',
            campaignId: data.updateReleaseCampaign.data.id,
          },
        });
        navigate(
          artistNavigationPath(
            artistHandle,
            `/event/insights/${data.updateReleaseCampaign.data.id}`,
          ),
        );
        setTimeout(() => {
          openToast({
            text: 'Drop successfully updated.',
            variant: 'success',
          });
        }, 500);

        setTimeout(() => {
          clearAllFields();
        }, 5000);

        onSuccess?.();
      }
    } catch (error) {
      logError({
        action: DROP_ERROR_ACTIONS.DROP_EDIT_VALIDATION_ERROR,
        error: error,
        level: 'fatal',
        message: 'There was an error updating the drop',
        errorType: ERROR_TYPE.MUTATION_ERROR,
        pillar,
        indexedTags: {
          source: 'useCampaignForm',
        },
        openToast,
        toast: 'There was an error updating the drop.',
      });
    } finally {
      setIsUpdating?.(false);
    }
  };

  const onUpdate = async ({
    setIsUpdating,
    onSuccess,
    campaignId,
  }: {
    setIsUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
    onSuccess?: () => void;
    campaignId: string;
  }) => {
    const baseInput = {
      releaseCampaignId: campaignId,
      artistHandle,
      contentType: fields.contentType,
      coverImageMediaId: fields.image && fields.mediaId ? fields.mediaId : undefined,
      description: fields.description || undefined,
      title: fields.title,
      externalLinks: fields.dsps
        .filter(dsp => dsp.uri !== '')
        .map((dsp, index) => ({
          cta:
            dsp.buttonText ||
            (dsp.buttonText === '' || dsp.buttonText === null ? 'Play' : dsp.buttonText),
          enabled: dsp.showLink,
          order: index,
          platform: dsp.key,
          url: dsp.uri,
        })),
      appleMusicResourceId: extractAppleMusicResourceId(
        fields.dsps.find(dsp => dsp.key === ThirdPartyPlatform.AppleMusic)?.uri,
      ),
      spotifyResourceId: extractSpotifyResourceIdFromUrl(
        fields.dsps.find(dsp => dsp.key === ThirdPartyPlatform.Spotify)?.uri,
      ),
    };

    const input = isPresave
      ? {
          ...baseInput,
          announcement:
            !fields.message || !fields.shouldSendSms || !fields.announcementDate
              ? null
              : {
                  date: fields.announcementDate.toISOString(),
                  message: fields.message,
                },
          isrc: fields.isrc || undefined,
          upc: fields.upc || undefined,
          shouldFindAppleMusicLink: fields.dsps.some(
            dsp => dsp.key === ThirdPartyPlatform.AppleMusic && dsp.uri === '',
          ),
          shouldFindSpotifyLink: fields.dsps.some(
            dsp => dsp.key === ThirdPartyPlatform.Spotify && dsp.uri === '',
          ),
          showSpotifyPresaveFirst: fields.showSpotifyPresaveFirst,
          showAppleMusicPresave: fields.showAppleMusicPresave,
          showSpotifyPresave: fields.showSpotifyPresave,
          releaseDate:
            fields.releaseDate && !fields.isReleaseDatePassed
              ? fields.releaseDate.toISOString()
              : undefined,
          releaseMessage:
            fields.releaseMessage &&
            fields.releaseDate &&
            fields.shouldSendReleaseSms &&
            !fields.isReleaseDatePassed
              ? fields.releaseMessage
              : undefined,
        }
      : {
          ...baseInput,
          announcement:
            !fields.message || !fields.shouldSendSms || !fields.announcementDate
              ? undefined
              : {
                  date: fields.announcementDate.toISOString(),
                  message: fields.message,
                },
          releaseDate: fields?.releaseDate?.toISOString() ?? new Date().toISOString(),
          releaseMessage: undefined,
        };

    return updateCampaign({
      input,
      setIsUpdating,
      onSuccess,
    });
  };

  const uploadCampaignImage = async (artistId: string, file: File) => {
    setIsUploading(true);
    try {
      const { mediaId, cdnUrl } = await uploadMultipartFile({
        file,
        mediaType: 'IMAGE',
        setProgress: bytes => {
          const progress = Math.floor((bytes / file.size) * 100);
          setProgress(progress);
        },
        artistId,
      });
      setField('mediaId', mediaId);
      setField('image', cdnUrl);
    } catch (error) {
      logError({
        action: DROP_ERROR_ACTIONS.DROP_ERROR,
        error: error,
        level: 'error',
        message: `There was an error uploading your image. ${error}`,
        errorType: ERROR_TYPE.UNKNOWN,
        pillar,
        indexedTags: {
          source: 'useCampaignForm - uploadCampaignImage',
          selectedFileName: file.name,
          selectedFileSize: file.size,
          selectedFileType: file.type,
        },
        openToast,
        toast: `There was an error uploading your image. ${error}`,
      });
    } finally {
      setProgress(0);
      setIsUploading(false);
    }
  };

  const deleteCampaign = async (campaignId: string) => {
    const input = {
      releaseCampaignId: campaignId,
    } satisfies MutationDeleteReleaseCampaignInput;

    try {
      const { data } = await deleteEvent({ input });
      if (data.deleteReleaseCampaign.__typename === 'MutationDeleteReleaseCampaignSuccess') {
        logInfo({
          action: DROP_INFO_ACTIONS.DROP_DELETE_START,
          message: 'User deleting release campaign',
          pillar: PILLARS.DROP,
          data: {
            artistHandle,
            campaignId,
          },
        });
        openToast({
          text: 'Drop successfully deleted.',
          variant: 'success',
        });
      }
    } catch (error) {
      logError({
        action: DROP_ERROR_ACTIONS.DROP_DELETE_ERROR,
        error: error,
        level: 'error',
        message: `There was an error deleting the drop. ${error}`,
        errorType: ERROR_TYPE.MUTATION_ERROR,
        pillar,
        indexedTags: {
          source: 'useCampaignForm ',
          campaignId,
        },
        openToast,
        toast: `There was an error deleting the drop. ${error}`,
      });
    }
  };

  const deactivateCampaign = async (campaignId: string) => {
    const input = {
      releaseCampaignId: campaignId,
    } satisfies MutationDeactivateReleaseCampaignInput;
    try {
      const { data } = await deactivateEvent({ input });
      if (
        data.deactivateReleaseCampaign.__typename === 'MutationDeactivateReleaseCampaignSuccess'
      ) {
        openToast({
          text: 'Drop is now private.',
          variant: 'success',
        });
      }
    } catch (error) {
      logError({
        action: DROP_ERROR_ACTIONS.DROP_ERROR,
        error: error,
        level: 'error',
        message: `There was an error deactivating the drop. ${error}`,
        errorType: ERROR_TYPE.MUTATION_ERROR,
        pillar,
        indexedTags: {
          source: 'useCampaignForm - deactivateCampaign',
          campaignId,
        },
        openToast,
        toast: `There was an error deactivating the drop. ${error}`,
      });
    }
  };

  const reactivateCampaign = async (campaignId: string) => {
    const input = {
      releaseCampaignId: campaignId,
    } satisfies MutationReactivateReleaseCampaignInput;
    try {
      const { data } = await reactivateEvent({ input });
      if (
        data.reactivateReleaseCampaign.__typename === 'MutationReactivateReleaseCampaignSuccess'
      ) {
        openToast({
          text: 'Drop is now public.',
          variant: 'success',
        });
      }
    } catch (error) {
      logError({
        action: DROP_ERROR_ACTIONS.DROP_ERROR,
        error: error,
        level: 'error',
        message: `There was an error activating the drop. ${error}`,
        errorType: ERROR_TYPE.MUTATION_ERROR,
        pillar,
        indexedTags: {
          source: 'useCampaignForm - reactivateCampaign',
          campaignId,
        },
        openToast,
        toast: `There was an error activating the drop. ${error}`,
      });
    }
  };

  return {
    fields,
    errors,
    enableSubmit,
    isUploading,
    isSubmitting,
    progress,
    isSetupComplete,
    isLinksComplete,
    isReleaseComplete,
    releaseCampaignCreationLoading,
    releaseCampaignUpdateLoading,
    releaseCampaignDeleting,
    releaseCampaignDeactivating,
    releaseCampaignReactivating,
    deleteCampaign,
    deactivateCampaign,
    reactivateCampaign,
    determineNextStep,
    determinePrevStep,
    uploadCampaignImage,
    onSubmit,
    onUpdate,
    clearFields: clearAllFields,
    clearErrors,
    setField,
    populateFields,
    validateField,
    setError,
  };
};
